import { css } from '@emotion/react';
import { fontSize } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { center } from 'style/global.styles';

export const root = css`
  width: 100%;
  position: relative;
  /* margin-top: calc(var(--unit) * 8); */
`;

export const uploadOverlay = css`
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;

  height: calc(var(--unit) * 48);
  width: 100%;

  @media ${breakpoints.medium} {
    max-width: calc(var(--unit) * 48);
  }
  svg {
    ${center};
  }
`;

export const uploadOverlayBorder = css`
  ${uploadOverlay};
  border: 1px dashed hsl(var(--color-borderLight-8));
`;

export const noHeight = css`
  height: 100% !important;
`;

export const labelWithButton = css`
  display: flex;
  justify-content: space-between;

  color: hsl(var(--color-borderDark-5));
`;

export const deleteLoadingIndicator = css`
  margin-left: auto;
  margin-bottom: calc(var(--unit) * 2);

  &,
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const deleteBtn = css`
  color: hsl(var(--color-error-7));
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: auto;
  margin-bottom: calc(var(--unit) * 2);
`;

export const loadingIndicator = css`
  height: 100%;
`;

export const progressEvent = css`
  ${center};
`;

export const uploadedImagePlaceholder = css`
  width: 100%;
  height: 100%;
`;

export const uploadedImage = (image?: string) => {
  if (image) {
    return css`
      background-image: url('${image}');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    `;
  }

  return '';
};

export const label = css`
  margin-bottom: calc(var(--unit) * 2);

  label {
    font-size: ${fontSize('small')};
    color: hsl(var(--color-grayscale-6));
  }
`;

export const fileInput = css`
  visibility: hidden;
  opacity: 0;
`;

export const hiddenInput = css`
  display: none;
`;

export const bottomLabel = css`
  margin: 0;
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  color: hsl(var(--color-grayscale-6));
  margin-top: calc(var(--unit) * 3);
`;

export const imageRepresentation = css`
  & > img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
